<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :tabs="tabs" title="My Events" subtitle="Browse the events you have joined or are hosting.">

	</app-content-head>

	<app-events-filter :filter="filter" :references="references" />

	<app-events v-on:hide="load" :noSuccess="true" :loading="is.loading && is.initialised" :filter="filter" :collection="collection" :columns="columns" :pagination="pagination" :total="total" placeholder="Sorry, no events found." />

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			endpoint: 'convention/schedule/events',
			live: 'convention/schedule/event',
			filterTemplate: 'events',
			references: {
				times: [],
				days: [],
				lobbies: []
			},
			params: {
				seated: true,
			},
			columns: {
				game: 200,
				seats: 100,
				date: 200,
				experience: 100,
				duration: 100,
				location: 'auto',
				actions: 72
			}
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Personal', text: 'All' },
				{ name: 'Convention.Schedule.Personal.Hosting', text: 'Hosting' }
			]

		}

	}

}

</script>

<style scoped>

</style>